@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #020617;
}

.hero-div {
  background-image: url("./assets/hero2.png");
  background-size: cover;
  background-position: 50%;
  position: relative;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.story {
  background-image: url("./assets/kids1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 80vh;
}

.navlink {
  &:hover {
    text-shadow: 0 0 10px #64ffda, 0 0 0px #64ffda, 0 0 10px #64ffda, 0 0 25px #64ffda;
  }
}

.neon-text-blue {
  color: #ffff;
  text-shadow: 0 0 10px #64ffda, 0 0 20px #64ffda, 0 0 40px #64ffda,
    0 0 80px #64ffda, 0 0 120px #64ffda;
  letter-spacing: 0.05em;
}

.neon-text-pink {
  color: #ffff;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 40px #ff00ff,
    0 0 80px #ff00ff, 0 0 120px #ff00ff;
  letter-spacing: 0.1em;
}

.pic-1,
.pic-2 {
  --s: 150px;
  height: 50vh;
}
.pic-1 {
  background-image: url("./assets/fax_modem.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - var(--s)));
}
.pic-2 {
  background-image: url("./assets/gameboy_fax_.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: calc(var(--s) * -1 + 20px);
  clip-path: polygon(0 0, 100% var(--s), 100% 100%, 0 100%);
}